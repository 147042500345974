import React, { useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { GRNFormValues, Product } from './GrnTypes';

interface FinalBillSummaryProps {
    formik: FormikProps<GRNFormValues>;
    onCancel: () => void;
}

const FinalBillSummary: React.FC<FinalBillSummaryProps> = ({
    formik,
    onCancel,
}) => {
    const { values, setFieldValue, handleSubmit, isSubmitting } = formik;
    const [docName, setDocName] = useState('');
    const [errors, setErrors] = useState<{
        invoice?: string;
    }>({});
    const fileRef = useRef<HTMLInputElement>(null);

    // Aggregate calculation functions
    const aggregateTotal = (products: Product[]): string => {
        const total = products.reduce((acc, product) => {
            const noOfStrips = Number(product.totalQuantity);
            const pricePerStrip = Number(product.pricePerStrip);
            return acc + noOfStrips * pricePerStrip;
        }, 0);
        return total.toFixed(2);
    };

    const aggregateDiscount = (products: Product[]): string => {
        const discount = products.reduce((acc, product) => {
            const noOfStrips = Number(product.totalQuantity);
            const pricePerStrip = Number(product.pricePerStrip);
            const discountPercent = Number(product.discount || 0);
            return acc + (noOfStrips * pricePerStrip * discountPercent) / 100;
        }, 0);
        return discount.toFixed(2);
    };

    const aggregateTax = (products: Product[]): string => {
        const tax = products.reduce((acc, product) => {
            const noOfStrips = Number(product.totalQuantity);
            const pricePerStrip = Number(product.pricePerStrip);
            const discountPercent = Number(product.discount || 0);
            const discount =
                (noOfStrips * pricePerStrip * discountPercent) / 100;
            const beforeTaxAmount = noOfStrips * pricePerStrip - discount;
            const gstTotal = Number(product.GST);
            return acc + (beforeTaxAmount * gstTotal) / 100;
        }, 0);
        return tax.toFixed(2);
    };

    const calcPurchaseAmount = (products: Product[]): string => {
        const taxAmount = Number(aggregateTax(products));
        const discountAmount = Number(aggregateDiscount(products));
        const totalAmount = Number(aggregateTotal(products));
        return (totalAmount - discountAmount + taxAmount).toFixed(2);
    };

    //upload handler with error handling
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setDocName(file.name);
            setFieldValue('invoiceFile', file);

            if (errors.invoice) {
                setErrors({
                    ...errors,
                    invoice: undefined,
                });
            }
        }
    };

    // Custom submit handler with validation
    const handleCustomSubmit = () => {
        if (!values.invoiceFile) {
            setErrors({
                ...errors,
                invoice: 'Please upload an invoice file',
            });
            return;
        }

        handleSubmit();
    };

    return (
        <div className="row">
            <div className="col-md-4 ">
                <div className="mb-4 ">
                    <input
                        ref={fileRef}
                        hidden
                        type="file"
                        accept="application/*,.pdf,.jpg,.jpeg,.png"
                        onChange={handleFileUpload}
                    />

                    <button
                        onClick={(event) => {
                            event.preventDefault();
                            fileRef?.current?.click();
                        }}
                        className="btn btn-md btn-primary "
                        style={{ marginRight: '1rem' }}
                    >
                        <i className="bi bi-upload me-2"></i>
                        Upload Invoice
                    </button>

                    {docName && (
                        <div className="mt-2">
                            <span className="badge bg-success p-2">
                                <i className="bi bi-file-earmark-check me-1"></i>
                                {docName}
                            </span>
                        </div>
                    )}

                    {errors.invoice && (
                        <div className="text-danger mt-2">{errors.invoice}</div>
                    )}
                </div>
            </div>

            <div className="col-md-8">
                <div className="card">
                    <div className="card-body p-0">
                        <table className="table table-bordered mb-0">
                            <tbody>
                                <tr>
                                    <td
                                        className="fw-bold text-end"
                                        width="60%"
                                    >
                                        Total
                                    </td>
                                    <td className="text-end" width="40%">
                                        ₹ {aggregateTotal(values.products)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fw-bold text-end">
                                        Overall Disc Value
                                    </td>
                                    <td className="text-end">
                                        ₹ {aggregateDiscount(values.products)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fw-bold text-end">
                                        <div>Total Tax (GST)</div>
                                        <div className="ms-3 text-muted">
                                            IGST
                                        </div>
                                        <div className="ms-3 text-muted">
                                            CGST
                                        </div>
                                        <div className="ms-3 text-muted">
                                            SGST/UTGST
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        <div>
                                            ₹ {aggregateTax(values.products)}
                                        </div>
                                        <div className="text-muted">₹ 0.00</div>
                                        <div className="text-muted">
                                            ₹{' '}
                                            {(
                                                Number(
                                                    aggregateTax(
                                                        values.products
                                                    )
                                                ) / 2
                                            ).toFixed(2)}
                                        </div>
                                        <div className="text-muted">
                                            ₹{' '}
                                            {(
                                                Number(
                                                    aggregateTax(
                                                        values.products
                                                    )
                                                ) / 2
                                            ).toFixed(2)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fw-bold text-end">
                                        Purchase Amount
                                    </td>
                                    <td className="text-end">
                                        ₹ {calcPurchaseAmount(values.products)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fw-bold text-end">
                                        Round Off
                                    </td>
                                    <td className="text-end">
                                        ₹{' '}
                                        {(
                                            Math.round(
                                                Number(
                                                    calcPurchaseAmount(
                                                        values.products
                                                    )
                                                )
                                            ) -
                                            Number(
                                                calcPurchaseAmount(
                                                    values.products
                                                )
                                            )
                                        ).toFixed(2)}
                                    </td>
                                </tr>
                                <tr className="table-active">
                                    <td className="fw-bold text-end fs-5">
                                        Net Value
                                    </td>
                                    <td className="fw-bold text-end fs-5">
                                        ₹{' '}
                                        {Math.round(
                                            Number(
                                                calcPurchaseAmount(
                                                    values.products
                                                )
                                            )
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="d-flex justify-content-end mt-4">
                    <button
                        type="button"
                        className="btn btn-light me-2"
                        onClick={onCancel}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </button>

                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleCustomSubmit}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm me-2"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Processing...
                            </>
                        ) : (
                            'Add GRN'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FinalBillSummary;
