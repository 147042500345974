import { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
import toast, { Toaster } from 'react-hot-toast';
import { useNavbar } from '../../../context/NavbarContext';
import useMutateRequest from '../../../hooks/getMutation.query';
import {
    generateHealthCard,
    getListOfAllPlans,
} from '../../../services/HealthCard.hook';
import useGetRequest from '../../../hooks/getRequest.query';
import {
    getErrorMessage,
    isErrorMsgNotReadable,
} from '../../../common/utils/genericErrors';

type ValueTypes = {
    facilityCode: string;
    totalCards: string;
    planId: string;
};

const generateCardSchema = Yup.object({
    facilityCode: Yup.string().required('Select Facility'),
    totalCards: Yup.number()
        .typeError('Please enter a valid number')
        .min(1, 'Healthcards should be minimum 1')
        .max(50, "Can't Generate more than 50 Cards")
        .required('Healthcards is Required'),
    planId: Yup.string(),
});

const GenerateCards = () => {
    const navigate = useNavigate();

    // Get list of plans
    const { data: planData }: any = useGetRequest(
        ['getListOfAllPlans'],
        async () => await getListOfAllPlans(),
        {
            refetchOnWindowFocus: false,
        }
    );

    const pricingPlans = planData?.data?.data?.plans?.filter(
        (plan: any) => plan.name !== 'Default'
    );

    const { userRole } = useNavbar();
    const [selectorDisabled, setSelectorDisabled] = useState(false);

    const facilityName: any = localStorage.getItem('facilityName');
    const facilityCode: any = localStorage.getItem('facilityCode');

    // API call for generating healthcards
    const { isLoading, isError, data, error, mutate, isSuccess }: any =
        useMutateRequest((data: any) => generateHealthCard(data));

    useEffect(() => {
        setSelectorDisabled(userRole === 'FACILITY MANAGER' ? true : false);
    }, [userRole]);

    const errorMsg = useMemo(
        () =>
            isErrorMsgNotReadable(error?.response?.data?.message)
                ? getErrorMessage(error?.response?.status)
                : error?.response?.data?.message,
        [error?.response?.data?.message]
    );
    const successMsg = data?.data?.message;

    const onSubmit = (values: ValueTypes, onSubmitProps: any) => {
        try {
            let formData = new FormData();
            const organizationId = JSON.parse(
                localStorage.getItem('organizationId') || '{}'
            );
            formData.append('organizationId', organizationId);
            formData.append('facilityCode', values.facilityCode);
            formData.append('numberOfCreate', values.totalCards);
            formData.append('planId', values.planId);
            mutate(formData, {
                onSuccess: (data: any) => {
                    setTimeout(() => {
                        onSubmitProps.resetForm();
                        navigate('/batch/list');
                    }, 3000);
                },
                onError: (error: any) => {
                    setTimeout(() => {
                        onSubmitProps.resetForm();
                    }, 3000);
                    console.log('Error while generating Health Cards:', error);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    {successMsg}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: 'transparent', border: '0' }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: 'top-right',
                id: 'success-toast',
            }
        );
    };

    const errorToastMsg = () => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {errorMsg}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: 'transparent', border: '0' }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: 'top-right',
                id: 'error-toast',
            }
        );
    };

    useEffect(() => {
        isSuccess && successToastMsg();
    }, [isSuccess]);

    useEffect(() => {
        isError && errorToastMsg();
    }, [isError]);

    return (
        <>
            <Toaster />
            {isLoading ? (
                <div className="card w-100 h-550px d-flex flex-column align-items-center justify-content-center p-15">
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : (
                <Formik
                    initialValues={{
                        facilityCode: facilityCode,
                        totalCards: '',
                        planId: '',
                    }}
                    validationSchema={generateCardSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {(formProps) => (
                        <Form>
                            <div className="card p-5">
                                <div className="h-550px w-100 d-flex p-5  flex-column justify-content-center align-items-center">
                                    <h1 className="card-title fs-1">
                                        Generate New Health Card
                                    </h1>
                                    <div className="fv-row mt-5">
                                        <label className="w-auto col-lg-2 col-form-label text-hover-primary required fw-bolder fs-5 mb-3">
                                            Selected Facility
                                        </label>
                                        <div className="fv-row">
                                            <Field
                                                name="facilities"
                                                className="w-500px form-control form-control-lg  required"
                                                // className="w-500px form-select form-select-light form-select-lg required"
                                                disabled={true}
                                                style={{
                                                    cursor: 'not-allowed',
                                                    fontSize: '15px',
                                                }}
                                                value={facilityName}
                                            />

                                            <div className="text-danger mt-5">
                                                <ErrorMessage name="facilities" />
                                            </div>
                                        </div>

                                        <div className="form-text mt-4">
                                            Select a Facility from the top to
                                            generate Health Cards.
                                        </div>
                                    </div>

                                    <div className="fv-row mt-5">
                                        <label className="w-auto col-lg-2 col-form-label text-hover-primary required fw-bolder fs-5 mb-3">
                                            Number of Health Cards
                                        </label>

                                        <div className="fv-4">
                                            <Field
                                                name="totalCards"
                                                className="w-500px form-control form-control-lg  required"
                                                placeholder="50 Cards per facility"
                                            />

                                            <div className="text-danger mt-5">
                                                <ErrorMessage name="totalCards" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fv-row mt-5">
                                        <label className="w-auto col-lg-2 col-form-label text-hover-primary fw-bolder fs-5 mb-3">
                                            Select healthcard plan
                                        </label>

                                        <div className="fv-4">
                                            <Field
                                                id="planId"
                                                as="select"
                                                name="planId"
                                                className="form-select form-select-light form-select-lg"
                                                style={{ width: '500px' }}
                                            >
                                                <option value="">
                                                    Select plan
                                                </option>
                                                {pricingPlans
                                                    ? pricingPlans?.map(
                                                          (price: any) => {
                                                              return (
                                                                  <option
                                                                      value={
                                                                          price?._id
                                                                      }
                                                                  >
                                                                      {price?.name
                                                                          ? price?.name
                                                                          : 'N/A'}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : 'N/A'}
                                            </Field>

                                            <div className="text-danger mt-5">
                                                <ErrorMessage name="planId" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-8">
                                        <button
                                            disabled={
                                                !formProps.isValid ||
                                                formProps.isSubmitting
                                            }
                                            type="submit"
                                            className="w-200px btn btn-primary mx-5"
                                        >
                                            Generate Cards
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default GenerateCards;
