import React, { useState, useEffect } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { GRNFormValues, Product } from './GrnTypes';

interface ProductTableErrorsProps {
    errors: FormikErrors<GRNFormValues>;
    touched: FormikTouched<GRNFormValues>;
    products?: Product[]; // Add this prop to pass the actual products
    forceShow?: boolean;
}

const ProductTableErrors: React.FC<ProductTableErrorsProps> = ({
    errors,
    touched,
    products = [], // Default to empty array
    forceShow = false,
}) => {
    const [isVisible, setIsVisible] = useState(true);
    const productErrors = Array.isArray(errors.products) ? errors.products : [];
    const touchedProducts = Array.isArray(touched.products)
        ? touched.products
        : [];

    // Collect all errors
    const allErrors = React.useMemo(() => {
        const errorList: {
            productIndex: number;
            productName: string;
            field: string;
            message: string;
        }[] = [];

        productErrors?.forEach((productError, idx) => {
            if (!productError || !touchedProducts?.[idx]) return;

            // Get the product name, fallback to index if not available
            const productName = products[idx]?.product || `Product ${idx + 1}`;

            Object.entries(productError).forEach(([field, errorMsg]) => {
                if (!errorMsg) return;

                errorList.push({
                    productIndex: idx,
                    productName,
                    field,
                    message: errorMsg as string,
                });
            });
        });

        return errorList;
    }, [productErrors, touchedProducts, products]);

    // Reset visibility when errors change or forceShow is true
    useEffect(() => {
        if (allErrors.length > 0 || forceShow) {
            setIsVisible(true);
        }
    }, [allErrors, forceShow]);

    // If no errors and not forced to show, return null
    if (allErrors.length === 0 && !forceShow) return null;

    // If not visible, return null
    if (!isVisible) return null;

    return (
        <div
            className="alert alert-warning alert-dismissible fade show d-flex flex-column align-items-start"
            role="alert"
        >
            <div>
                <h5 className="alert-heading mb-4 d-flex align-items-center text-center">
                    <i className="fas fa-exclamation-triangle me-3 fs-2 text-danger"></i>
                    Validation Warnings
                    <span className="badge bg-warning text-dark ms-2">
                        {allErrors.length}
                    </span>
                </h5>
            </div>
            <div>
                <ul className="list-unstyled mb-0">
                    {allErrors.map(
                        (
                            { productIndex, productName, field, message },
                            index
                        ) => (
                            <li
                                key={`${productIndex}-${field}-${index}`}
                                className="mb-1 text-danger"
                            >
                                <strong>
                                    {productName} -{' '}
                                    {field.charAt(0).toUpperCase() +
                                        field.slice(1)}
                                    :
                                </strong>{' '}
                                <span className="">{message}</span>
                            </li>
                        )
                    )}
                </ul>
            </div>
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setIsVisible(false)}
            ></button>
        </div>
    );
};

export default ProductTableErrors;
