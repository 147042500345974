import * as Yup from 'yup';

export const validationSchemaForAddSalesOrder = Yup.object().shape({
    patientName: Yup.string().required('Please select a patient'),
    productCode: Yup.string().required('Bar code is missing'),
    product: Yup.string().required('Please select a product'),
    manufacturer: Yup.string().required('Manufacturer is required'),
    batch: Yup.string().required('Batch is required'),
    expiryDate: Yup.string()
        .required('Expiry date is required')
        .test(
            'is-not-expired',
            'The product has already expired',
            function (value) {
                if (!value) return true;
                const currentDate = new Date();
                const expiryDate = new Date(value as string);
                return expiryDate > currentDate;
            }
        )
        .test(
            'is-more-than-current-date',
            'The product is about to expire',
            function (value) {
                if (!value) return true;
                const currentDate = new Date();
                const thirtyDaysFromNow = new Date(currentDate);
                thirtyDaysFromNow.setDate(currentDate.getDate() + 30);
                const expiryDate = new Date(value as string);
                return expiryDate > thirtyDaysFromNow;
            }
        ),

    units: Yup.number()
        .required('Units is required')
        .test(
            'is-within-available-quantity',
            'Entered units exceed available quantity',
            function (value?: number) {
                const { totalQuantity } = this.parent as {
                    totalQuantity: number;
                };
                return value !== undefined && value <= totalQuantity;
            }
        ),

    mrpPerUnit: Yup.number().required('MRP per unit is required'),
    discountPercent: Yup.number().test(
        'is-less-than-100',
        'Discount should be less than 100',
        function (value: any) {
            return value < 100;
        }
    ),
});
