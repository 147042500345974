const gstOptions = ['0', '5', '12', '18', '28'];

const GSTSelect: React.FC<{
    value: string;
    onChange: (value: string) => void;
}> = ({ value, onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        onChange(e.target.value);
    };

    return (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '30px' }}
        >
            <select
                value={value}
                onChange={handleChange}
                className="form-select form-select-sm gst-select"
                style={{
                    fontSize: '0.7rem',
                    padding: '2px 20px 2px 5px',
                    height: '26px',
                    textAlign: 'center',
                    width: '100%',
                }}
            >
                {gstOptions.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default GSTSelect;
