import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import useGetRequest from '../../../../hooks/getRequest.query';
import { getPurchaseOrderDetails } from '../../../../services/supply-chain/purchase-order/purchaseOrderList';
import EditableProductsTable from './GrnProductTable';
import GRNForm from './GRNForm';
import FinalBillSummary from './FinalBillSummary';
import { GRNFormValues, Product, validationSchema, Vendor } from './GrnTypes';
import useMutateRequest from '../../../../hooks/getMutation.query';
import { createGrn } from '../../../../services/supply-chain/grn/modifyGrn.services';
import {
    getErrorMessage,
    isErrorMsgNotReadable,
} from '../../../../common/utils/genericErrors';

const AddGRN: React.FC = () => {
    const [searchParams] = useSearchParams();
    const purchaseOrderIdForApi = searchParams.get('purchaseOrderId') as string;
    const orderId = searchParams.get('orderId') as string;

    const navigate = useNavigate();

    // State to store processed products and vendors
    const [processedProducts, setProcessedProducts] = useState<Product[]>([]);
    const [vendor, setVendor] = useState<Vendor>();

    // Fetch purchase order details
    const {
        data: purchaseOrderDetails,
        isError: isPurchaseOrderDetailsError,
        error: purchaseOrderDetailsError,
    } = useGetRequest(['getPurchaseOrderDetails'], () =>
        getPurchaseOrderDetails({ purchaseOrderId: orderId })
    );

    const { isLoading, mutate } = useMutateRequest((data: any) =>
        createGrn(data)
    );

    // Initial form values
    const initialValues: GRNFormValues = {
        vendorName: vendor ? vendor.label : '',
        vendorId: vendor ? vendor.vendorId : '',
        invoiceNumber: '',
        invoiceDate: new Date().toISOString().split('T')[0],
        invoiceFile: null,
        products: processedProducts,
        purchaseOrderId: purchaseOrderIdForApi,
    };

    // Handle form submission
    const handleSubmit = (values: GRNFormValues) => {
        const formData = new FormData();

        // Append form values to FormData
        formData.append('vendorId', values.vendorId);
        formData.append('invoiceNumber', values.invoiceNumber);
        formData.append('invoiceDate', values.invoiceDate);

        if (values.invoiceFile) {
            formData.append('invoiceFile', values.invoiceFile);
        }

        formData.append('products', JSON.stringify(values.products));
        formData.append('status', 'Active');
        formData.append('purchaseOrderId', values.purchaseOrderId);

        // Get facilityCode from localStorage
        const facilityCode = localStorage.getItem('facilityCode');
        if (facilityCode) {
            formData.append('facilityCode', facilityCode);
        }

        // Simulate API call
        setTimeout(() => {
            mutate(formData, {
                onSuccess(data: any) {
                    toast.success(
                        (t) => (
                            <span
                                data-testid="success-msg"
                                className="p-2 fs-4 d-flex align-items-center justify-content-center "
                            >
                                {/* {successMsg} */}
                                Successfully created GRN
                                <button
                                    className="ms-15"
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: '0',
                                    }}
                                    onClick={() => toast.dismiss(t.id)}
                                >
                                    <i className="fs-4 fas fa-times text-hover-primary"></i>
                                </button>
                            </span>
                        ),
                        {
                            duration: 5000,
                            position: 'top-right',
                            id: 'success-toast',
                        }
                    );
                    setTimeout(() => {
                        navigate(
                            `/purchase-order/details?purchaseOrderId=${orderId}&orderId=${purchaseOrderDetails}`
                        );
                    }, 1500);
                },
                onError(error: any) {
                    toast.error(
                        (t) => (
                            <span
                                data-testid="error-msg"
                                className="p-2 fs-4 d-flex align-items-center justify-content-center w-100"
                            >
                                {isErrorMsgNotReadable(
                                    error?.response?.data?.message
                                )
                                    ? getErrorMessage(error?.response?.status)
                                    : error?.response?.data?.message}
                                <button
                                    className="ms-15"
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: '0',
                                    }}
                                    onClick={() => toast.dismiss(t.id)}
                                >
                                    <i className="fs-4 fas fa-times text-hover-primary"></i>
                                </button>
                            </span>
                        ),
                        {
                            duration: 5000,
                            position: 'top-right',
                            id: 'error-toast',
                        }
                    );
                },
            });
        }, 1000);
    };

    // Prepare initial products from purchase order
    useEffect(() => {
        if (purchaseOrderDetails) {
            const product = purchaseOrderDetails?.data?.data?.purchaseOrder;

            if (product) {
                // Process products
                const newProcessedProducts: Product[] =
                    product?.products.map((item: any) => ({
                        productId: item.productId._id || 'N/A',
                        product: item.productId?.productName || 'N/A',
                        batch: '',
                        rackNo: '',
                        boxNo: '',
                        expiry: '',
                        unitsPerStrip: Number(item.unitsPerStrip || 1),
                        totalQuantity: Number(item.totalStrips || 0),
                        freeQuantity: 0,
                        GST: 0,
                        IGST: 0,
                        CGST: 0,
                        SGST: 0,
                        pricePerUnit: (
                            item.pricePerStrip / item.unitsPerStrip
                        ).toFixed(2),
                        pricePerStrip: Number(item.pricePerStrip || 0),
                        ratePerUnit: Number(
                            item.pricePerStrip / item.unitsPerStrip || 0
                        ),
                        ratePerStrip: Number(item.pricePerStrip || 0),
                        discount: 0,
                    })) || [];

                setProcessedProducts(newProcessedProducts);

                setVendor({
                    label: product.vendorId.vendorName || 'N/A',
                    vendorId: product.vendorId.vendorId || 'N/A',
                });
            }
        }
    }, [purchaseOrderDetails]);

    // Handle API error
    useEffect(() => {
        if (isPurchaseOrderDetailsError && purchaseOrderDetailsError) {
            toast.error('Failed to fetch purchase order details', {
                duration: 3000,
                position: 'top-right',
            });
        }
    }, [isPurchaseOrderDetailsError, purchaseOrderDetailsError]);

    return (
        <>
            <Toaster />
            <div className="container p-3">
                <div className="card">
                    <div className="card-header m-0 p-0 px-3 border-bottom-0">
                        <h2 className="mt-5">Add Goods Receipt Note (GRN)</h2>
                    </div>
                    <div className="card-body pt-0">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize={true}
                        >
                            {(formik) => (
                                <Form>
                                    {isPurchaseOrderDetailsError ? (
                                        <div className="alert alert-danger">
                                            Failed to load purchase order
                                            details.
                                        </div>
                                    ) : (
                                        <>
                                            <GRNForm formik={formik} />

                                            <EditableProductsTable
                                                formik={formik}
                                            />

                                            <FinalBillSummary
                                                formik={formik}
                                                onCancel={() => navigate(-1)}
                                            />
                                        </>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddGRN;
