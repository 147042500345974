import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { parse, isValid } from 'date-fns';

export interface Vendor {
    label: string;
    vendorId: string;
}

export interface Product {
    productId: string;
    product: string;
    batch: string;
    rackNo: string;
    boxNo: string;
    expiry: string;
    unitsPerStrip: number;
    totalQuantity: number;
    freeQuantity: number;
    GST: number;
    IGST: number;
    CGST: number;
    SGST: number;
    pricePerUnit: string;
    pricePerStrip: number;
    ratePerUnit: number;
    ratePerStrip: number;
    discount: number;
}

export interface GRNFormValues {
    vendorName: string;
    vendorId: string;
    invoiceNumber: string;
    invoiceDate: string;
    invoiceFile?: File | null;
    products: Product[];
    purchaseOrderId: string;
}

export interface EditableProductsTableProps {
    formik: {
        values: GRNFormValues;
        setFieldValue: FormikHelpers<GRNFormValues>['setFieldValue'];
    };
}

// Validation schema
export const validationSchema = Yup.object({
    vendorName: Yup.string().required('Vendor Name is required'),
    invoiceNumber: Yup.string().required('Invoice Number is required'),
    invoiceDate: Yup.date().required('Invoice Date is required'),
    invoiceFile: Yup.mixed().required('Invoice File is required'),
    products: Yup.array()
        .of(
            Yup.object({
                batch: Yup.string().required('Batch is required'),
                expiry: Yup.string()
                    .required('Expiry Date is required')
                    .test(
                        'valid-date',
                        'Expiry Date must be more than 30 days from current date',
                        function (value) {
                            if (!value) return false;

                            const inputDate = new Date(value);
                            if (isNaN(inputDate.getTime())) return false;

                            const thirtyDaysFromNow = new Date();
                            thirtyDaysFromNow.setDate(
                                thirtyDaysFromNow.getDate() + 30
                            );

                            return inputDate > thirtyDaysFromNow;
                        }
                    ),
                GST: Yup.number()
                    .required('GST is required')
                    .min(1, 'GST is required'),
                unitsPerStrip: Yup.number()
                    .required('Units per Strip is required')
                    .positive('Units per Strip must be positive')
                    .integer('Units per Strip must be a whole number'),
                rackNo: Yup.string().required('Rack is required'),
                boxNo: Yup.string().required('Box is required'),
            })
        )
        .min(1, 'At least one product is required'),
});
