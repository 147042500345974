import React, { useRef, useState } from 'react';

const EditableCell: React.FC<{
    value: string;
    onChange: (value: string) => void;
    type?: 'text' | 'number' | 'date';
    validate?: (value: string) => boolean;
}> = ({ value, onChange, type = 'text', validate }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(value);
    const cellRef = useRef<HTMLDivElement>(null);

    const handleClick = (): void => setIsEditing(true);

    const handleBlur = (): void => {
        if (validate ? validate(inputValue) : true) {
            onChange(inputValue);
        }
        setIsEditing(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            if (validate ? validate(inputValue) : true) {
                onChange(inputValue);
            }
            setIsEditing(false);
        }
    };

    // Function to format date or show placeholder
    const formatDate = (dateString: string): string => {
        if (!dateString) {
            return 'dd-mm-yyyy';
        }
        try {
            return new Date(dateString).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        } catch {
            return 'dd-mm-yyyy';
        }
    };

    // Function to display value with placeholder
    const displayValue = (): string => {
        // If it's a date type, use formatDate
        if (type === 'date') {
            return formatDate(inputValue);
        }

        // For other types, return '-' if empty, otherwise return the value
        return inputValue?.trim() ? inputValue : '-';
    };

    return (
        <div
            ref={cellRef}
            className="position-relative w-100"
            style={{ height: '100%' }}
        >
            {isEditing ? (
                <input
                    type={type}
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    className="form-control form-control-sm text-center"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        padding: '2px',
                        fontSize: '0.7rem',
                    }}
                />
            ) : (
                <div
                    className="cursor-pointer editable-cell text-hover-primary d-flex align-items-center justify-content-center"
                    onClick={handleClick}
                    style={{
                        cursor: 'pointer',
                        width: '100%',
                        minHeight: '30px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'center',
                    }}
                >
                    {displayValue()}
                </div>
            )}
        </div>
    );
};

export default EditableCell;
