import React, { useEffect, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { Product, GRNFormValues } from './GrnTypes';
import EditableCell from './EditableCell';
import GSTSelect from './GSTSelect';
import ProductTableErrors from './ProductTableError';

const EditableProductsTable: React.FC<{
    formik: FormikProps<GRNFormValues>;
}> = ({ formik }) => {
    const { values, setFieldValue, errors, touched } = formik;
    const productsArr = values.products;

    // Simplified column headers to save space
    const tableNames = [
        'product',
        'batch',
        'expiry date',
        'gst',
        'igst',
        'sgst',
        'cgst',
        'units/strip',
        'no of strips',
        'free strips',
        'total units',
        'price',
        'mrp',
        'discount %',
        'tax',
        'total price',
        'rack',
        'box',
        'action',
    ];

    // Further reduced column widths
    const columnWidths = {
        slNo: '30px',
        product: '100px',
        batch: '60px',
        expiryDate: '80px',
        gstTotal: '40px',
        igst: '40px',
        sgstUtgst: '40px',
        cgst: '40px',
        unitsPerStrip: '65px',
        noOfStrips: '60px',
        freeStrips: '65px',
        totalUnits: '65px',
        pricePerStrip: '60px',
        mrpPerStrip: '60px',
        discount: '60px',
        inTax: '50px',
        totalPrice: '70px',
        rackNo: '40px',
        boxNo: '40px',
        action: '40px',
    };

    // Calculate total units
    const calcTotalUnits = (
        noOfStrips: number,
        freeStrips: number,
        unitsPerStrip: number
    ): number => {
        return (noOfStrips + freeStrips) * unitsPerStrip;
    };

    // Calculate tax amount
    const calcTaxAmt = (
        noOfStrips: number,
        pricePerStrip: number,
        discountPercent: number,
        freeAmount: number,
        gstTotal: number
    ): string => {
        const discountAmount =
            (noOfStrips * pricePerStrip * discountPercent) / 100;
        const beforeTaxAmount =
            noOfStrips * pricePerStrip - discountAmount - freeAmount;
        const taxAmount = (beforeTaxAmount * gstTotal) / 100;
        return taxAmount.toFixed(2);
    };

    // Calculate total price
    const calcTotalPrice = (
        noOfStrips: number,
        pricePerStrip: number,
        discountPercent: number,
        freeAmount: number,
        gstTotal: number
    ): string => {
        const discountAmount =
            (noOfStrips * pricePerStrip * discountPercent) / 100;
        const beforeTaxAmount =
            noOfStrips * pricePerStrip - discountAmount - freeAmount;
        const taxAmount = (beforeTaxAmount * gstTotal) / 100;
        const totalPrice = beforeTaxAmount + taxAmount;
        return totalPrice.toFixed(2);
    };

    // Delete product
    const deleteProduct = (productId: string): void => {
        const updatedProducts = productsArr.filter(
            (item) => item.productId !== productId
        );
        setFieldValue('products', updatedProducts);
    };

    // Handle cell value change
    const handleCellChange = (
        idx: number,
        field: keyof Product,
        value: string
    ): void => {
        const updatedProducts = [...productsArr];
        updatedProducts[idx] = {
            ...updatedProducts[idx],
            [field]: value,
        };
        setFieldValue('products', updatedProducts);
    };

    // Validation function for expiry date (must be more than 30 days from now)
    const validateExpiryDate = (dateString: string): boolean => {
        if (!dateString) return false;

        // Try to create a valid date object
        const inputDate = new Date(dateString);

        // Check if the date is valid and more than 30 days from now
        if (isNaN(inputDate.getTime())) return false;

        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);

        return inputDate > thirtyDaysFromNow;
    };

    return (
        <div className="container-fluid px-0 ">
            <style>
                {`
                .table-cell {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 0.7rem;
                     position: relative;
                }
                .compact-table th {
                    font-size: 0.7rem;
                    padding: 2px 2px !important;
                    text-transform: uppercase;
                    vertical-align: middle;
                }
                .compact-table td {
                    padding: 2px 2px !important;
                    vertical-align: middle;
                }
                .compact-table .btn-sm {
                    padding: 0 0.25rem !important;
                    font-size: 0.7rem;
                }
                .compact-table p {
                    margin: 0;
                    font-size: 0.7rem;
                }
                
                .form-select-sm {
                    background-position: right 2px center !important;
                    background-size: 10px !important;
                    padding-right: 16px !important;
                }
                
                .gst-select.form-select {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-position: right 2px center;
                    background-size: 10px 10px;
                }
                .compact-table .error-message {
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    width: 100%;
                    background-color: #f8d7da;
                    color: #721c24;
                    font-size: 0.6rem;
                    padding: 2px 4px;
                    border-radius: 3px;
                    z-index: 10;
                }
               
                `}
            </style>
            <div className="table-responsive">
                <table
                    className="table table-striped table-bordered compact-table"
                    style={{
                        tableLayout: 'fixed',
                        width: '100%',
                        fontSize: '0.7rem',
                    }}
                >
                    {productsArr.length > 0 && (
                        <thead>
                            <tr>
                                <th
                                    className="text-center fw-bold table-cell"
                                    style={{ width: columnWidths.slNo }}
                                >
                                    Sl no
                                </th>
                                {tableNames?.map((name, index) => {
                                    const widthKey = Object.keys(columnWidths)[
                                        index + 1
                                    ] as keyof typeof columnWidths;

                                    // Add required class to specific headers
                                    const requiredFields = [
                                        'batch',
                                        'expiry date',
                                        'gst',
                                        'rack',
                                        'box',
                                    ];

                                    const headerClassName = `text-center fw-bold table-cell ${
                                        requiredFields.includes(
                                            name.toLowerCase()
                                        )
                                            ? 'required'
                                            : ''
                                    }`;

                                    return (
                                        <th
                                            key={name}
                                            className={headerClassName}
                                            style={{
                                                width: columnWidths[widthKey],
                                            }}
                                        >
                                            {name}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        {productsArr.length === 0 ? (
                            <tr
                                style={{
                                    height: '30px',
                                    background: 'white',
                                }}
                            >
                                <td
                                    colSpan={tableNames.length + 2}
                                    className="text-center fs-6 fw-bold text-danger"
                                >
                                    No products found
                                </td>
                            </tr>
                        ) : (
                            productsArr.map((ele, idx) => (
                                <tr
                                    key={ele.productId}
                                    style={{
                                        height: '15px',
                                    }}
                                >
                                    <td
                                        className="text-center table-cell"
                                        style={{
                                            width: columnWidths.slNo,
                                        }}
                                    >
                                        {idx + 1}
                                    </td>
                                    {/* Product - Not editable */}
                                    <td
                                        className="table-cell text-center"
                                        style={{ width: columnWidths.product }}
                                    >
                                        {ele.product}
                                    </td>
                                    {/* Batch - Not editable */}
                                    <td
                                        className="table-cell text-center"
                                        style={{ width: columnWidths.batch }}
                                    >
                                        <EditableCell
                                            value={ele.batch || ''}
                                            onChange={(value) =>
                                                handleCellChange(
                                                    idx,
                                                    'batch',
                                                    value
                                                )
                                            }
                                            type="number"
                                        />
                                    </td>
                                    {/*Expiry Date Column - Editable with validation */}
                                    <td
                                        className="table-cell"
                                        style={{
                                            width: columnWidths.expiryDate,
                                        }}
                                    >
                                        <EditableCell
                                            value={ele.expiry || ''}
                                            onChange={(value) =>
                                                handleCellChange(
                                                    idx,
                                                    'expiry',
                                                    value
                                                )
                                            }
                                            type="date"
                                            validate={validateExpiryDate}
                                        />
                                    </td>
                                    {/* GST % - Now a Select Menu */}
                                    <td
                                        className="table-cell"
                                        style={{
                                            width: columnWidths.gstTotal,
                                            padding: '0 1px',
                                        }}
                                    >
                                        <GSTSelect
                                            value={String(ele.GST)}
                                            onChange={(value) =>
                                                handleCellChange(
                                                    idx,
                                                    'GST',
                                                    value
                                                )
                                            }
                                        />
                                    </td>
                                    {/* IGST % - Always 0 */}
                                    <td
                                        className="table-cell text-center"
                                        style={{ width: columnWidths.igst }}
                                    >
                                        0
                                    </td>
                                    {/* SGST/UTGST % - Calculated from GST */}
                                    <td
                                        className="table-cell text-center"
                                        style={{
                                            width: columnWidths.sgstUtgst,
                                        }}
                                    >
                                        {(
                                            parseInt(String(ele.GST)) / 2
                                        ).toFixed(1)}
                                    </td>
                                    {/* CGST % - Calculated from GST */}
                                    <td
                                        className="table-cell text-center"
                                        style={{ width: columnWidths.cgst }}
                                    >
                                        {(
                                            parseInt(String(ele.GST)) / 2
                                        ).toFixed(1)}
                                    </td>
                                    {/* Units / Strip - Editable */}
                                    <td
                                        className="table-cell"
                                        style={{
                                            width: columnWidths.unitsPerStrip,
                                        }}
                                    >
                                        <EditableCell
                                            value={String(ele.unitsPerStrip)}
                                            onChange={(value) =>
                                                handleCellChange(
                                                    idx,
                                                    'unitsPerStrip',
                                                    value
                                                )
                                            }
                                            type="number"
                                        />
                                    </td>
                                    {/*Number of Strips - Editable*/}
                                    <td
                                        className="table-cell"
                                        style={{
                                            width: columnWidths.noOfStrips,
                                        }}
                                    >
                                        <EditableCell
                                            value={
                                                String(ele.totalQuantity) || '0'
                                            }
                                            onChange={(value) =>
                                                handleCellChange(
                                                    idx,
                                                    'totalQuantity',
                                                    value
                                                )
                                            }
                                            type="number"
                                        />
                                    </td>
                                    {/*Free Strips - Editable */}
                                    <td
                                        className="table-cell"
                                        style={{
                                            width: columnWidths.freeStrips,
                                        }}
                                    >
                                        <EditableCell
                                            value={
                                                String(ele.freeQuantity) || '0'
                                            }
                                            onChange={(value) =>
                                                handleCellChange(
                                                    idx,
                                                    'freeQuantity',
                                                    value
                                                )
                                            }
                                            type="number"
                                        />
                                    </td>
                                    {/* Total Units - Calculated field */}
                                    <td
                                        className="table-cell text-center"
                                        style={{
                                            width: columnWidths.totalUnits,
                                        }}
                                    >
                                        {(() => {
                                            const totalUnits = calcTotalUnits(
                                                Number(ele.totalQuantity) || 0,
                                                Number(ele.freeQuantity) || 0,
                                                Number(ele.unitsPerStrip)
                                            );
                                            return (
                                                <div className="text-danger">
                                                    {totalUnits}
                                                </div>
                                            );
                                        })()}
                                    </td>

                                    {/* Price / Strip - Editable */}
                                    <td
                                        className="table-cell"
                                        style={{
                                            width: columnWidths.pricePerStrip,
                                        }}
                                    >
                                        <EditableCell
                                            value={String(ele.pricePerStrip)}
                                            onChange={(value) =>
                                                handleCellChange(
                                                    idx,
                                                    'pricePerStrip',
                                                    value
                                                )
                                            }
                                            type="number"
                                        />
                                    </td>
                                    {/* MRP / Strip - Editable */}
                                    <td
                                        className="table-cell"
                                        style={{
                                            width: columnWidths.mrpPerStrip,
                                        }}
                                    >
                                        <EditableCell
                                            value={String(ele.ratePerStrip)}
                                            onChange={(value) =>
                                                handleCellChange(
                                                    idx,
                                                    'ratePerStrip',
                                                    value
                                                )
                                            }
                                            type="number"
                                        />
                                    </td>
                                    {/* Discount - Editable */}
                                    <td
                                        className="table-cell text-center"
                                        style={{ width: columnWidths.discount }}
                                    >
                                        <EditableCell
                                            value={`${ele.discount ?? 0}`}
                                            onChange={(value) => {
                                                handleCellChange(
                                                    idx,
                                                    'discount',
                                                    value
                                                );
                                            }}
                                            type="number"
                                        />
                                    </td>

                                    {/* In TAX (Rs.) - Calculated field */}
                                    <td
                                        className="table-cell text-center"
                                        style={{ width: columnWidths.inTax }}
                                    >
                                        {calcTaxAmt(
                                            +ele.totalQuantity,
                                            +ele.pricePerStrip,
                                            +(ele.discount || ''),
                                            0,
                                            +ele.GST
                                        )}
                                    </td>
                                    {/* Total Price - Calculated field */}
                                    <td
                                        className="table-cell text-center"
                                        style={{
                                            width: columnWidths.totalPrice,
                                        }}
                                    >
                                        {calcTotalPrice(
                                            +ele.totalQuantity,
                                            +ele.pricePerStrip,
                                            +(ele.discount || '0'),
                                            0,
                                            +ele.GST
                                        )}
                                    </td>
                                    {/* Rack No - Editable */}
                                    <td
                                        className="table-cell"
                                        style={{ width: columnWidths.rackNo }}
                                    >
                                        <EditableCell
                                            value={ele.rackNo || ''}
                                            onChange={(value) =>
                                                handleCellChange(
                                                    idx,
                                                    'rackNo',
                                                    value
                                                )
                                            }
                                            type="text"
                                        />
                                    </td>

                                    {/* Box No - Editable */}
                                    <td
                                        className="table-cell"
                                        style={{ width: columnWidths.boxNo }}
                                    >
                                        <EditableCell
                                            value={ele.boxNo || ''}
                                            onChange={(value) =>
                                                handleCellChange(
                                                    idx,
                                                    'boxNo',
                                                    value
                                                )
                                            }
                                            type="text"
                                        />
                                    </td>

                                    {/* Actions */}
                                    <td
                                        className="text-center table-cell"
                                        style={{ width: columnWidths.action }}
                                    >
                                        <button
                                            className="btn btn-sm p-0 px-1 text-hover-danger"
                                            data-testid="delete-btn"
                                            onClick={() =>
                                                deleteProduct(ele.productId)
                                            }
                                        >
                                            <i className="fa-solid fa-trash fa-xs"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            <ProductTableErrors
                errors={errors}
                touched={touched}
                products={productsArr} // Pass the products array
            />
        </div>
    );
};

export default EditableProductsTable;
