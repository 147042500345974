import * as Yup from 'yup';

export const validationSchemaForAddSalesOrder = Yup.object().shape({
    product: Yup.string().required('Please select a product'),
    manufacturer: Yup.string().required('Manufacturer is required'),
    batch: Yup.string().required('Batch is required'),
    expiryDate: Yup.date()
        .test(
            'is-more-than-current-date',
            'The product is about to expire',
            function (value: any) {
                const currentDate = new Date();
                const thirtyDaysFromNow = new Date();
                thirtyDaysFromNow.setDate(currentDate.getDate());
                return value > thirtyDaysFromNow;
            }
        )
        .required('Expiry date is required'),
    units: Yup.number()
        .required('Units is required')
        .test(
            'is-within-available-quantity',
            'Entered units exceed available quantity',
            function (value?: number) {
                const { totalQuantity } = this.parent as {
                    totalQuantity: number;
                };
                return value !== undefined && value <= totalQuantity;
            }
        ),

    mrpPerUnit: Yup.number().required('MRP per unit is required'),
    discountPercent: Yup.number().test(
        'is-less-than-100',
        'Discount should be less than 100',
        function (value: any) {
            return value < 100;
        }
    ),
});
