import $axiosProtected from '../../common/utils/axiosUtils';

// Get Healthcard info
export const getHealthCardInfo = async (payload: any) => {
    return await $axiosProtected().get(
        `/healthcard/get?healthCard=${payload.healthCard}`
    );
};

// Get Healthcard plan info
export const getHealthCardPlanInfo = async (payload: any) => {
    return await $axiosProtected().get(
        `/healthcard/get/pricings?healthCard=${payload.patientHealthCardNo}`
    );
};

// Get all beneficiary by healthcard
export const getBeneficiaryByHealthcard = async (payload: any) => {
    return await $axiosProtected().get(
        `/patient/beneficiary/list/web-portal?healthCardNumber=${payload.healthCardNumber}`
    );
};

// Get all healthcard subscription plans except default
export const getAllHealthCardPlans = async () =>
    await $axiosProtected().get(`/healthcard/plan/get/all/except/default`);

export const addSubscription = async (payload: any) => {
    return await $axiosProtected().post(`/healthcard/patient/plan`, payload);
};

// New submit Api to add subscription from the Patient details page
export const addHealthCardSubscription = async (payload: any) => {
    return await $axiosProtected().post(`/healthcard/add/plan`, payload);
};

export const addMember = async (payload: any) => {
    return await $axiosProtected().post(
        `/healthcard/beneficiary/store`,
        payload
    );
};

/**
 *  NOT IN USE
 */
export const updatePatientProfile = async (payload: any) => {
    return await $axiosProtected().patch(`/patient/profile/update`, payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
