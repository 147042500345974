import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { AutoCompleteInput, TextInput } from '../../../../components/Inputs';
import ErrorMessageText from '../../../../components/ErrorMessageText/ErrorMessageText';
import useGetRequest from '../../../../hooks/getRequest.query';
import { getAllVendors } from '../../../../services/supply-chain/vendor/Vendor.services';
import { capitalize } from 'lodash';
import { GRNFormValues, Vendor } from './GrnTypes';

interface GRNFormProps {
    formik: FormikProps<GRNFormValues>;
}

const GRNForm: React.FC<GRNFormProps> = ({ formik }) => {
    const { data: vendorData } = useGetRequest('getAllVendors', () =>
        getAllVendors({ page: 1, limit: 1000 })
    );

    const vendorOptions = useMemo(
        () =>
            vendorData
                ? vendorData?.data?.data?.vendors.map((ele: any) => ({
                      label: capitalize(ele.vendorName),
                      vendorId: ele.vendorId,
                  }))
                : [],
        [vendorData]
    );

    return (
        <div className="row mt-5">
            <div className="col">
                <AutoCompleteInput
                    label="Vendor Name"
                    name="vendorName"
                    data-testid="vendor-name"
                    onChange={(e: any, value: Vendor | null) => {
                        formik.setFieldValue(
                            'vendorName',
                            value ? value.label : ''
                        );
                        formik.setFieldValue(
                            'vendorId',
                            value ? value.vendorId : ''
                        );
                    }}
                    value={
                        formik.values.vendorName
                            ? {
                                  label: formik.values.vendorName,
                                  // @ts-ignore
                                  vendorId: formik.values.vendorId,
                              }
                            : { label: '', year: '' }
                    }
                    optionsArr={vendorOptions}
                    isOptionEqualToValue={(
                        option: { vendorId: string },
                        value: { vendorId: string }
                    ) => option.vendorId === value.vendorId}
                    required
                    className="mb-3"
                />

                {formik.touched.vendorName && formik.errors.vendorName && (
                    <ErrorMessageText message={formik.errors.vendorName} />
                )}
            </div>

            <div className="col">
                <TextInput
                    className="mb-3"
                    controlId="invoiceNumber"
                    label="Invoice Number"
                    data-testid="invoice-number"
                    placeholder="Enter Invoice Number"
                    type="text"
                    name="invoiceNumber"
                    onChange={formik.handleChange}
                    value={formik.values.invoiceNumber}
                    required
                />
                {formik.touched.invoiceNumber &&
                    formik.errors.invoiceNumber && (
                        <ErrorMessageText
                            message={formik.errors.invoiceNumber}
                        />
                    )}
            </div>

            <div className="col">
                <TextInput
                    className="mb-3"
                    controlId="invoiceDate"
                    label="Invoice Date"
                    type="date"
                    data-testid="invoice-date"
                    name="invoiceDate"
                    onChange={formik.handleChange}
                    value={formik.values.invoiceDate}
                    max={new Date().toISOString().split('T')[0]}
                    required
                />
                {formik.touched.invoiceDate && formik.errors.invoiceDate && (
                    <ErrorMessageText message={formik.errors.invoiceDate} />
                )}
            </div>
        </div>
    );
};

export default GRNForm;
