import React from 'react';

import { IProductsArr } from '../SalesOrderForm/IForms';
import {
    aggregateDiscount,
    aggregateGst,
    aggregateTotal,
    totalAmt,
} from './CalcFunctions';
import useMutateRequest from '../../../../../hooks/getMutation.query';
import { createSalesOrder } from '../../../../../services/supply-chain/sales-order/modify-sales-order/index.services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import {
    getErrorMessage,
    isErrorMsgNotReadable,
} from '../../../../../common/utils/genericErrors';

type Props = {
    productsArr: IProductsArr;
};

const FinalBillSummary = ({ productsArr }: Props) => {
    const { mutate } = useMutateRequest((data: any) => createSalesOrder(data));
    const facilityCode = localStorage.getItem('facilityCode') as string;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const salesOrderId = searchParams.get('salesOrderId') as string;

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    Sales order successfully created!
                    <button
                        className="ms-15"
                        style={{ backgroundColor: 'transparent', border: '0' }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: 'top-right',
                id: 'success-toast-1',
            }
        );
    };

    const errorToastMsg = (err: string) => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {err}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: 'transparent', border: '0' }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: 'top-right',
                id: 'error-toast-1',
            }
        );
    };

    const handleCreateSales = () => {
        const finalProductArr = productsArr.map((ele: any) => {
            return {
                productId: ele.productId,
                batch: ele.batch,
                GST: +ele.gstTotal,
                IGST: 0,
                CGST: +ele.gstTotal / 2,
                SGST: +ele.gstTotal / 2,
                totalQuantity: ele.units,
                ratePerUnit: ele.mrpPerUnit,
                discount: +ele.discountPercent,
                totalPriceValue: +totalAmt(
                    +ele.mrpPerUnit,
                    +ele.units,
                    +ele.discountPercent
                ),
                inventoryId: ele.inventoryId,
                unitsPerStrip: ele.unitsPerStrip,
            };
        });

        const formData = new FormData();
        formData.append('salesOrderReferenceId', salesOrderId);
        formData.append('products', JSON.stringify(finalProductArr));
        formData.append('facilityCode', facilityCode);
        formData.append(
            'totalAmount',
            JSON.stringify(
                Math.round(
                    +aggregateTotal(productsArr) +
                        +aggregateGst(productsArr) -
                        +aggregateDiscount(productsArr)
                )
            )
        );
        formData.append('deliveryStatus', 'Order Placed');
        mutate(formData, {
            onSuccess: (data: any) => {
                successToastMsg();
                setTimeout(() => {
                    navigate(-1);
                }, 1500);
            },
            onError: (error: any) => {
                errorToastMsg(
                    isErrorMsgNotReadable(error?.response?.data?.message)
                        ? getErrorMessage(error?.response?.status)
                        : error?.response?.data?.message
                );
            },
        });
    };
    return (
        <>
            <Toaster />
            <div className="row">
                <div className="col-6"></div>

                <div className="col-sm">
                    <table
                        className="table table-bordered mb-4"
                        style={{ borderBottom: '1px solid #eff2f5' }}
                    >
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border">
                                <td
                                    className="border p-2 font-weight-bold"
                                    style={{ textAlign: 'right' }}
                                >
                                    Total (Before Tax)
                                </td>
                                <td
                                    className="border p-2"
                                    style={{ textAlign: 'right' }}
                                >
                                    {aggregateTotal(productsArr)}
                                </td>
                            </tr>

                            <tr className="border">
                                <td
                                    className="border p-2 font-weight-bold"
                                    style={{ textAlign: 'right' }}
                                >
                                    <p className="text font-weight-bold">
                                        Total Tax (GST)
                                    </p>
                                    <p className="text font-weight-bold">
                                        IGST
                                    </p>
                                    <p className="text font-weight-bold">
                                        CGST
                                    </p>
                                    <p className="text font-weight-bold">
                                        SGST/UTGST
                                    </p>
                                </td>
                                <td
                                    className="border p-2"
                                    style={{ textAlign: 'right' }}
                                >
                                    <p className="text font-weight-bold">
                                        {aggregateGst(productsArr)}
                                    </p>
                                    <p className="text font-weight-bold">
                                        0.00
                                    </p>
                                    <p className="text font-weight-bold">
                                        {(
                                            +aggregateGst(productsArr) / 2
                                        ).toFixed(2)}
                                    </p>
                                    <p className="text font-weight-bold">
                                        {(
                                            +aggregateGst(productsArr) / 2
                                        ).toFixed(2)}
                                    </p>
                                </td>
                            </tr>
                            <tr className="border">
                                <td
                                    className="border p-2 font-weight-bold"
                                    style={{ textAlign: 'right' }}
                                >
                                    OverAll Disc Value
                                </td>
                                <td
                                    className="border p-2"
                                    style={{ textAlign: 'right' }}
                                >
                                    {aggregateDiscount(productsArr)}
                                </td>
                            </tr>
                            <tr className="border">
                                <td
                                    className="border p-2 font-weight-bold"
                                    style={{ textAlign: 'right' }}
                                >
                                    Total Amount
                                </td>
                                <td
                                    className="border p-2"
                                    style={{ textAlign: 'right' }}
                                >
                                    {(
                                        +aggregateTotal(productsArr) +
                                        +aggregateGst(productsArr) -
                                        +aggregateDiscount(productsArr)
                                    ).toFixed(2)}
                                </td>
                            </tr>
                            <tr className="border">
                                <td
                                    className="border p-2 font-weight-bold"
                                    style={{ textAlign: 'right' }}
                                >
                                    Round Off
                                </td>
                                <td
                                    className="border p-2"
                                    style={{ textAlign: 'right' }}
                                >
                                    {(
                                        Math.round(
                                            +aggregateTotal(productsArr) +
                                                +aggregateGst(productsArr) -
                                                +aggregateDiscount(productsArr)
                                        ) -
                                        (+aggregateTotal(productsArr) +
                                            +aggregateGst(productsArr) -
                                            +aggregateDiscount(productsArr))
                                    ).toFixed(2)}
                                </td>
                            </tr>
                            <tr
                                className="border"
                                style={{ textAlign: 'right' }}
                            >
                                <td
                                    className="border p-2 font-weight-bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    Net Value
                                </td>
                                <td
                                    className="border p-2"
                                    style={{
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                    }}
                                >
                                    {Math.round(
                                        +aggregateTotal(productsArr) +
                                            +aggregateGst(productsArr) -
                                            +aggregateDiscount(productsArr)
                                    ).toFixed(2)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ textAlign: 'right' }}>
                        <button
                            className="btn btn-light"
                            style={{ marginRight: '1rem' }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => handleCreateSales()}
                        >
                            Create Sales Order
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FinalBillSummary;
